<template>
  <span>
    <h3 id="titulo" class="text-center">Extrato de Consignações</h3>
    <div>
      <table id="tabela">
        <thead>
          <tr>
            <th>Consignatária</th>
            <th>Data de Cadastro</th>
            <th>Contrato</th>
            <th>Prazo Total</th>
            <th>Prazo Restante</th>
            <th>Situação</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in extratoConsignacoesList">
            <tr v-for="data in item.consignacoes" :key="data.id">
              <td>{{ item.consignatariaDto.nomeFantasia }}</td>
              <td>{{ data.auditMetadata.cadastradoEm | formatarData }}</td>
              <td>{{ data.numeroContrato }}</td>
              <td>{{ data.prazoTotal }}</td>
              <td>{{ data.prazoRestante }}</td>
              <td>{{ data.statusConsignacao }}</td>
              <td>{{ data.valorParcela | formatarValor }}</td>
            </tr>
            <tr id="subTotal" :key="index">
              <td colspan="6" class="text-right">SubTotal</td>
              <td class="formatarValor">
                {{ item.subTotal | formatarValor }}
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6" class="text-right">Total</td>
            <td class="formatarValor">{{ total | formatarValor }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </span>
</template>
<script>
import { storeRelatorio } from '@/stores/relatorio'

export default {
  setup() {
    const relatorioStore = storeRelatorio()
    return { relatorioStore }
  },
  data() {
    return {
      extratoConsignacoesList: [],
      total: 0,
    }
  },

  created() {
    this.extratoConsignacoesList = this.calcularSubTotal(
      this.relatorioStore.response[0].extratoConsignacaoDto,
    )
  },

  mounted() {},

  methods: {
    calcularSubTotal(data) {
      data.forEach((item) => {
        item.subTotal = this.calcularSubTotalPorExtrato(item.consignacoes)
      })
      return data
    },

    calcularSubTotalPorExtrato(consignacoes) {
      let subTotal = 0

      consignacoes.forEach((consignacao) => {
        subTotal += consignacao.valorParcela
      })

      this.total += subTotal

      return subTotal
    },
  },
}
</script>
<style lang="scss" scoped>
#titulo {
  font-weight: bold;
}

.formatarValor {
  text-align: right !important;
}

#tabela {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
}
#tabela th {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: left;
}

#tabela td {
  padding: 10px;
}

#tabela tfoot {
  background-color: #666666;
  color: #fff;
}

#subTotal {
  background-color: #f2f2f2;
}

@media print {
  #titulo {
    font-weight: bold;
  }

  .formatarValor {
    text-align: right !important;
  }

  #tabela {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
  }
  #tabela th {
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: left;
  }

  #tabela td {
    padding: 10px;
  }

  #tabela tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #tabela tfoot {
    background-color: #666666;
    color: #fff;
  }
}
</style>
