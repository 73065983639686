<template>
  <span>
    <h3 id="titulo" class="text-center">Relatório de Prestações Descontadas</h3>
    <div>
      <table id="tabela">
        <thead>
          <tr>
            <th>Competência</th>
            <th>Matricula</th>
            <th>CPF</th>
            <th>Nome</th>
            <th>Entidade</th>
            <th>Valor da Prestação</th>
            <th>Valor Averbação</th>
            <th>Valor Descontado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(prestacao, index) in prestacoesDescontadas" :key="index">
            <td>{{ prestacao.mesReferencia }}/{{ prestacao.anoReferencia }}</td>
            <td>{{ prestacao.matricula }}</td>
            <td>{{ prestacao.cpfServidor }}</td>
            <td>{{ prestacao.nomeServidor }}</td>
            <td>{{ prestacao.nomeEntidade }}</td>
            <td>R$ {{ prestacao.valorParcela }}</td>
            <td>
              R$
              {{
                prestacao.prazoTotal !== 0
                  ? prestacao.prazoTotal * prestacao.valorParcela
                  : prestacao.valorParcela
              }}
            </td>
            <td>R$ {{ prestacao.valorDescontado }}</td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  </span>
</template>
<script>
import { storeRelatorio } from '@/stores/relatorio'

export default {
  setup() {
    const relatorioStore = storeRelatorio()
    return { relatorioStore }
  },

  data() {
    return {
      prestacoesDescontadas: [],
    }
  },
  created() {
    this.prestacoesDescontadas = this.relatorioStore.response
  },

  methods: {},
}
</script>
<style lang="scss" scoped>
#titulo {
  font-weight: bold;
}

.formatarValor {
  text-align: right !important;
}

#tabela {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
}
#tabela th {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: left;
}

#tabela td {
  padding: 10px;
}

#tabela tfoot {
  background-color: #666666;
  color: #fff;
}

#subTotal {
  background-color: #f2f2f2;
}

@media print {
  #titulo {
    font-weight: bold;
  }

  .formatarValor {
    text-align: right !important;
  }

  #tabela {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
  }
  #tabela th {
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: left;
  }

  #tabela td {
    padding: 10px;
  }

  #tabela tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #tabela tfoot {
    background-color: #666666;
    color: #fff;
  }
}
</style>
