<template>
  <span>
    <h3 id="titulo" class="text-center">Relatório de Taxa Assistencial</h3>
    <div>
      <table id="tabela">
        <thead>
          <tr>
            <th>Resp.</th>
            <th>Consignatário</th>
            <th>Atividade</th>
            <th>Valor</th>
            <th>Quantidade Desconto</th>
            <th>Valor Desconto</th>
            <th>Total Folha</th>
            <th>Total Tarifas</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in relatorioStore.response" :key="data.responsavel">
            <td>{{ data.responsavel }}</td>
            <td style="width: 50px">{{ data.consignatario }}</td>
            <td>{{ data.atividade }}</td>
            <td class="formatarValor">
              R${{ data.valorPadrao | formatarValorRelatorio }} 
            </td>
            <td class="formatarValor">
              R${{ data.quantidadeDescontos | formatarValorRelatorio }}
            </td>
            <td class="formatarValor">
              R${{ data.valorDescontos | formatarValorRelatorio }}
            </td>
            <td class="formatarValor">
              R${{ data.totalFolha | formatarValorRelatorio }}
            </td>
            <td class="formatarValor">
              R${{ data.totalTarifas | formatarValorRelatorio }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4" class="text-right">Total</td>
            <td class="formatarValor">
              R${{ totalQuantidadeDescontos | formatarValorRelatorio }}
            </td>
            <td class="formatarValor">
              R${{ totalValorDescontos | formatarValorRelatorio }}
            </td>
            <td class="formatarValor">
              R${{ totalTotalFolha | formatarValorRelatorio }}
            </td>
            <td class="formatarValor">
            R${{ totalTotalTarifas | formatarValorRelatorio }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </span>
</template>
<script>
import { storeRelatorio } from '@/stores/relatorio'

export default {
  setup() {
    const relatorioStore = storeRelatorio()
    return { relatorioStore }
  },

  data() {
    return {
      totais: {},
    }
  },

  created() {
    this.adicionarTotal()
    this.removerTotalGeralDasLista()
  },

  methods: {
    adicionarTotal() {
      this.totais =
        this.relatorioStore.response[this.relatorioStore.response.length - 1]
    },

    removerTotalGeralDasLista() {
      this.relatorioStore.response.pop()
    },

    calcularQuantidadeDescontos(data) {
      this.totalQuantidadeDescontos += data.quantidadeDescontos
    },

    calcularValorDescontos(data) {
      this.totalValorDescontos += data.valorDescontos
    },

    calcularTotalFolha(data) {
      this.totalTotalFolha += data.totalFolha
    },

    calcularTotalTarifas(valor) {
      this.totalTotalTarifas += valor.totalTarifas
    },
  },
}
</script>
<style lang="scss" scoped>
#titulo {
  font-weight: bold;
}

.formatarValor {
  padding-right: 20px !important;
  text-align: right !important;
}

#tabela {
  border-collapse: collapse;
  font-size: 12px;
}
#tabela th {
  background-color: #333;
  color: #fff;
  padding: 8px;
}

#tabela td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

#tabela tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

#tabela tfoot {
  background-color: #666666;
  color: #fff;
}

@media print {
  #titulo {
    font-weight: bold;
  }

  .formatarValor {
    text-align: right !important;
  }

  #tabela {
    border-collapse: collapse;
    font-size: 12px;
  }
  #tabela th {
    background-color: #333;
    color: #fff;
    padding: 8px;
  }

  #tabela td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  #tabela tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #tabela tfoot {
    background-color: #666666;
    color: #fff;
  }
}
</style>
